body {
    margin: 0;
    overflow: hidden;
}

.sidebar .sidebar-fechado {
    cursor: pointer;
}

.modules-grid {

    flex-direction: column;
    height: 75vh;
    color: #fff;

}



.sidebar-aberto {
    color:red;
    font-size: 25px;
    padding: 10px;
}

.exit {
    margin-top: 85vh;
    margin-left: 2vh;
}

.sidebar-fechado {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}



.img-sidebar {
    margin: 10px 0 20px 0;
    width: 250px;
    
}


.modules {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    align-items: center;
    margin: 5px 0 5px 0;

    &:hover {
        color: #D9D9D9;

    }
}

.sub-modules {
    background-color: #D9D9D9;
    margin: 1px 0;
    display: flex;
    justify-content: center;
}

.modules-icon-01 {
    font-size: 24px;
    padding-right: 10px;
    cursor: pointer;

}

.modules-icon-02 {
    font-size: 18px;
    margin: 2px 0;
    cursor: pointer;
    color: black;
    &:hover {
        color: #fff;

    }
}

.iframe {

    position: fixed;
    top: 0;
    right: 0;
    width: 96.7vw;
    height: 100vh;

}
.placeholder-image{
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
}

.drawer-open {
    position: relative;
    background-color: rgb(39, 44, 53);
    width: 50px;
    z-index: 9999;

}

.drawer-closed {
    background-color: rgb(39, 44, 53);
    width: 250px;
    z-index: 9999;

}

.drawer-open {
    width: 250px;

}

.drawer-closed {
    width: 65px;
    overflow: none;
}



.logo-container {
    margin-top: 20px;
    text-align: center;
}
.sidebar-grid-aberto{
    display: flex;
    flex-direction: column;
    align-items: center;
 
}
.dash-container {
    display: flex;
    color: #fff;
    margin-top: 100px;
    text-align: center;
    cursor: pointer;
}

.img-sidebar {
    width: 200px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.modules-grid {
    display: flex;
    flex-direction: column;
}

.color {
    flex-grow: 1;
    color: #fff;
}

.bi {
    padding: 10px;
    color: gold;
    font-size: 25px;
}
.dash {
    padding: 10px;
    color: #fff;
    font-size: 25px;
    &:hover {
        transform: scale(1.50);
        color: gray;
    }
}

.logo-bi {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bi-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.powerbi-text {
    font-weight: bold;
    font-size: 12px;
    color: #fff;
    /*writing-mode: vertical-rl;
    text-orientation: upright;*/
}

.organizarSelect{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}