/* Classe para escurecer o fundo */
.dark-background {
    position: relative;
}

.dark-background::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    /* Escurecimento */
    z-index: 1;
}

.textarea-focused {
    position: relative;
    z-index: 2;
}



.linha-horizontal-flexbox {
    display: flex;
    align-items: center;
    /* Alinha ao centro verticalmente */
    width: 100%;
    /* Largura total */
    height: 1px;
    /* Altura da linha */
    background-color: #ccc;
    /* Cor da linha */
}

.scroll {
    overflow-y: auto;
    /* Certifique-se de que o overflow está habilitado */
}

.scroll::-webkit-scrollbar {
    width: 10px;
    /* Largura da barra de rolagem */
}

.scroll::-webkit-scrollbar-track {
    background:  #f4f4f5;
    /* Cor do trilho (fundo da barra) */
    border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
    background: #9a9a9a;
    border-radius: 10px;
    border: 2px solid #ccc;
    /* Borda para criar efeito de espaçamento */
}

.custom-swal-container {
    background-color: rgba(0, 0, 0, 0.8);
    /* Fundo semi-transparente */
}

.custom-swal-popup {
    background-color: #f4f4f5;
    /* Cor de fundo do popup */
    border-radius: 15px;
    /* Bordas arredondadas */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    /* Sombra */
}

.custom-swal-title {
    font-size: 22px;
    /* Tamanho da fonte do título */
    color: #3a3f42;
    /* Cor do título */
    font-weight: bold;
    /* Negrito */
}

.custom-swal-confirm-button {
    background-color: #4caf50;
    /* Cor de fundo do botão de confirmação */
    color: white;
    /* Cor do texto */
    border-radius: 8px;
    /* Bordas arredondadas */
    padding: 10px 20px;
    /* Padding interno */
    font-size: 16px;
    /* Tamanho da fonte */
}

.custom-swal-cancel-button {
    background-color: #f44336;
    /* Cor de fundo do botão de cancelamento */
    color: white;
    /* Cor do texto */
    border-radius: 8px;
    /* Bordas arredondadas */
    padding: 10px 20px;
    /* Padding interno */
    font-size: 16px;
    /* Tamanho da fonte */
}

.custom-swal-icon {
    color: #f44336;
    /* Cor do ícone de alerta */
}